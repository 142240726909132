import React, { useLayoutEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import UCCLOGO from '../images/logo-ucc-2018.svg'
import { findUrl } from '../helpers/findUrl'
import DynamicZoneMainHeader from '../helpers/createDynamicZoneMainHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { TranslateOverlay } from './home/TranslateOverlay'
import Icon from './icon'
import { useTheme } from '../context/themeContext'
import { Moon, Sun } from './headerIcons'

const Header = ({ page, logo }) => {
  const data = useStaticQuery(graphql`
    query {
      allSitePage(
        filter: {
          componentChunkName: {
            in: [
              "component---src-templates-main-render-ou-js"
              "component---src-templates-internal-page-js"
            ]
          }
        }
      ) {
        nodes {
          path
        }
      }
      strapiInstitution {
        secondaryLogoDark {
          image {
            url
          }
        }
      }
    }
  `)

  const { allSitePage: matchPages, strapiInstitution: institution } = data

  const [languageChangeDesktop, setLanguageChangeDesktop] = useState(false)
  const [languageChangeMobile, setLanguageChangeMobile] = useState(false)
  const { theme, toggleTheme } = useTheme()

  useLayoutEffect(() => {
    const overlayEl = document.querySelector('#google_translate_element')
    if (overlayEl) overlayEl.setAttribute('class', 'translate-overlay-dark')
    return () => {
      if (overlayEl) overlayEl.removeAttribute('class')
    }
  }, [languageChangeDesktop, languageChangeMobile])
  
  return (
    <header className="bg-transparent fixed-top">
      <Navbar
        expand="lg"
        variant={theme}
        className="shadow flex-lg-column pt-lg-4 pb-lg-3 navbar mr-auto nav-primary-container navbar-scroll navbar-scroll-style2"
      >
        <div className="w-100 d-flex flex-column flex-lg-row">

          {/* Imagen del Navbar */}
          <div className="flex-grow-1 d-flex flex-row align-items-center">
            <Navbar.Brand>
              <Link to="/">
                <img
                  src={
                    theme === 'dark' && logo?.imageDark
                      ? logo?.imageDark.url
                      : theme === 'dark' && institution?.secondaryLogoDark
                        ? institution?.secondaryLogoDark.image.url
                        : theme === 'light' && logo?.imageLight
                          ? logo?.imageLight.url
                          : UCCLOGO
                  }
                  alt={logo?.title || 'UCC'}
                  className="img-fluid mr-2 logo"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              color="red"
              aria-controls="basic-navbar-nav"
              className="ml-auto pr-0 border-0 nav-primary-color"
            />
          </div>

          {/* Button theme and translate on laptop */}
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1 d-none d-lg-flex align-items-center justify-content-start justify-content-md-end featured-navbar-index font-secondary navbar-nav ">
              <div className="d-flex">
                <div className="nav-primary-color translate-overlay">
                  <button onClick={toggleTheme} className="theme-toggle">
                    {theme === 'dark' ? <Moon /> : <Sun />}
                  </button>
                  <FontAwesomeIcon
                    onClick={() => setLanguageChangeDesktop((prev) => !prev)}
                    icon={faGlobe}
                  />
                  {languageChangeDesktop && <TranslateOverlay />}
                </div>
              </div>
              <DynamicZoneMainHeader pages={matchPages} />
            </div>
          </div>
        </div>
        
        <Navbar.Collapse id="basic-navbar-nav" className="w-100 mt-lg-2">
          {/* Button theme and translate on mobile */}
          <div className="row">
            <div className="col">
              <div className="d-lg-none d-flex  align-items-center justify-content-start justify-content-md-end featured-navbar-index font-secondary navbar-nav border-top border-bottom py-3">
                <div className="d-lg-none d-flex  ">
                  <div className="nav-primary-color translate-overlay">
                    <button onClick={toggleTheme} className="theme-toggle">
                      {theme === 'dark' ? <Moon /> : <Sun />}
                    </button>
                    <FontAwesomeIcon
                      onClick={() => setLanguageChangeMobile((prev) => !prev)}
                      icon={faGlobe}
                    />
                    {languageChangeMobile && <TranslateOverlay />}
                  </div>
                </div>
                <DynamicZoneMainHeader pages={matchPages} />
              </div>
            </div>
          </div>

          <Nav className="nav-dropdown-white mr-auto font-secondary font-weight-medium">
            {page?.navbar?.map((menu, index) => {
              if (
                /********* Map para navItems dropdowns - Multiple Options *********/
                menu.organizationalUnit?.find((item) => item.organizational_unit.slug !== null) ||
                menu.pages?.find((item) => item.page.slug !== null) ||
                menu.careersList?.ucc_careers?.find((item) => item.slug !== null) ||
                menu.ExternalLink?.find((item) => item.url !== null) ||
                menu.multipleSite
              ) {
                return (
                  <NavDropdown
                    title={`${menu.name || menu.careersList?.name}`}
                    id="basic-nav-dropdown"
                    key={`navBarId-${index}`}
                    className="nav-link-text text-uppercase"
                  >
                    {/* Map pages */}
                    <div className="nav-scroll navbar-scroll-style2">
                      {menu.pages?.map((dropdown, index) => {
                        if (dropdown.page.slug)
                          return (
                            <Link
                              to={findUrl(dropdown.page.slug, matchPages.nodes)}
                              className="dropdown-item"
                              key={`menuPagesId-${index}`}
                            >
                              <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                {dropdown.icon &&
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={true}
                                  />
                                }
                                <small className="nav-text">
                                  {dropdown.name}
                                </small>
                              </h6>
                            </Link>
                          )
                      })}
                    </div>

                    {/* Map organizationalUnit */}
                    <div className="nav-scroll navbar-scroll-style2">
                      {menu.organizationalUnit?.map((dropdown, index) => {
                        if (dropdown.organizational_unit.slug) {
                          return (
                            <Link
                              to={`/${dropdown.organizational_unit.slug}`}
                              className="dropdown-item"
                              key={`menuCareersId-${index}`}
                            >
                              <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                {dropdown.icon &&
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={true}
                                  />
                                }
                                <small className="nav-text">
                                  {dropdown.name}
                                </small>
                              </h6>
                            </Link>
                          )
                        }
                      })}
                    </div>

                    {/* Map uccCarrers */}
                    <div className="nav-scroll navbar-scroll-style2">
                      {menu.careersList?.ucc_careers?.map((dropdown, index) => {
                        if (dropdown.slug)
                          return (
                            <Link
                              to={`/carreras/${dropdown?.slug}`}
                              className="dropdown-item"
                              key={`menuCareersId-${index}`}
                            >
                              <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                {dropdown.icon &&
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={true}
                                  />
                                }
                                <small className="nav-text">
                                  {dropdown?.name}
                                </small>
                              </h6>
                            </Link>
                          )
                      })}
                    </div>

                    {/* Map multiple external links */}
                    <div className="nav-scroll navbar-scroll-style2">
                      {menu.ExternalLink?.map((dropdown, index) => {
                        if (dropdown?.url)
                          return (
                            <Link
                              to={dropdown.url || ''}
                              target="_blank"
                              className="dropdown-item"
                              key={`menuOrganizationalUnitId-${index}`}
                              rel="noreferrer"
                            >
                              <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                {dropdown.icon &&
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={true}
                                  />
                                }
                                <small className="nav-text">
                                  {dropdown.name}
                                </small>
                              </h6>
                            </Link>
                          )
                      })}
                    </div>

                    {/* Map MultipleSites */}
                    <div className="nav-scroll navbar-scroll-style2">
                      {menu.multipleSite?.map((dropdown, index) => {
                        switch (true) {
                          case dropdown.page !== null:
                            return (
                              <Link
                                to={findUrl(
                                  dropdown.page.slug,
                                  matchPages.nodes
                                )}
                                className="dropdown-item"
                                key={`menuPagesId-${index}`}
                              >
                                <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={dropdown.name.trim()}
                                  />
                                  <small className="nav-text">
                                    {dropdown.name}
                                  </small>
                                </h6>
                              </Link>
                            )

                          case dropdown.organizational_unit !== null:
                            return (
                              <Link
                                to={`/${dropdown.organizational_unit?.slug}`}
                                className="dropdown-item"
                                key={`menuOrganizationalUnitId-${index}`}
                              >
                                <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={dropdown.organizational_unit.name.trim()}
                                  />
                                  <small className="nav-text">
                                    {dropdown?.name || dropdown.organizational_unit.name}
                                  </small>
                                </h6>
                              </Link>
                            )

                          case dropdown.url !== null:
                            return (
                              <a
                                href={dropdown.url}
                                target="_blank"
                                className="dropdown-item"
                                key={`menuUrlId-${index}`}
                                rel="noreferrer"
                              >
                                <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                  <Icon
                                    {...dropdown.icon}
                                    className="mr-1"
                                    hasMargin={dropdown.name.trim()}
                                  />
                                  <small className="nav-text">
                                    {dropdown.name}
                                  </small>
                                </h6>
                              </a>
                            )

                          case dropdown.novelty !== null:
                            return (
                              <Link
                                to={`/noticias/${dropdown.novelty?.slug}`}
                                className="dropdown-item"
                                key={`menuNoveltyId-${index}`}
                              >
                                <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={dropdown.novelty.title.trim()}
                                  />
                                  <small className="nav-text">
                                    {dropdown?.name || dropdown.novelty.title}
                                  </small>
                                </h6>
                              </Link>
                            )

                          case dropdown.note !== null:
                            return (
                              <Link
                                to={`/notas/${dropdown.note?.slug}`}
                                className="dropdown-item"
                                key={`menuNoteId-${index}`}
                              >
                                <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={dropdown.note.title.trim()}
                                  />
                                  <small className="nav-text">
                                    {dropdown?.name || dropdown.note.title}
                                  </small>
                                </h6>
                              </Link>
                            )
                        
                          case dropdown.ucc_career !== null:
                            return (
                              <Link
                                to={`/carreras/${dropdown.ucc_career?.slug}`}
                                className="dropdown-item"
                                key={`menuCareerId-${index}`}
                              >
                                <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={dropdown.ucc_career?.name.trim()}
                                  />
                                  <small className="nav-text">
                                    {dropdown?.name || dropdown.ucc_career?.name}
                                  </small>
                                </h6>
                              </Link>
                            )

                          default:
                            break;
                        }
                      })}
                    </div>
                  </NavDropdown>
                )
              } else {
                /********* Map para navItems - Simples *********/
                if (
                  (menu.page && menu.page !== null) ||
                  (menu.link && menu.link !== null) ||
                  (menu.organizational_unit &&
                    menu.organizational_unit !== null) ||
                  (menu.url && menu.url !== null)
                ) {
                  if (menu.page && menu.page?.slug) {
                    return (
                      <Link
                        to={findUrl(menu.page.slug, matchPages.nodes)}
                        key={`menuPageId-${index}`}
                        className="nav-link nav-link-text text-uppercase d-flex flex-row"
                      >
                        {menu.icon && <Icon {...menu.icon} hasMargin={true}/>}
                        {menu.name}
                      </Link>
                    )
                  }
                  if (menu.career && menu.career.slug) {
                    return (
                      <Link
                        to={`/${page.slug}/${menu.career?.slug}`}
                        key={`menuCareerIdd-${index}`}
                        className="nav-link nav-link-text text-uppercase d-flex flex-row"
                      >
                        {menu.name}
                      </Link>
                    )
                  }
                  if (menu.link && menu.link.url) {
                    return (
                      <Link
                        to={`/${page.slug}/${menu.link?.url}`}
                        key={`menuLinkId-${index}`}
                        className="nav-link nav-link-text text-uppercase d-flex flex-row"
                      >
                        {menu.icon && <Icon {...menu.icon} hasMargin={true}/>}
                        {menu.name}
                      </Link>
                    )
                  }
                  if (menu.organizational_unit && menu.organizational_unit.slug) {
                    return (
                      <Link
                        to={`/${menu.organizational_unit?.slug}`}
                        key={`menuLinkId-${index}`}
                        className="nav-link nav-link-text text-uppercase d-flex flex-row"
                      >
                        {menu.icon && <Icon {...menu.icon} hasMargin={true}/>}
                        {menu.name}
                      </Link>
                    )
                  }
                  if (menu.url) {
                    return (
                      <Link
                        to={menu.url || ''}
                        target="_blank"
                        key={`menuLinkId-${index}`}
                        className="nav-link nav-link-text text-uppercase d-flex flex-row"
                        rel="noreferrer"
                      >
                        {menu.icon && <Icon {...menu.icon} hasMargin={true}/>}
                        {menu.name}
                      </Link>
                    )
                  }
                }
              }
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header

Header.propTypes = {
  page: PropTypes.object.isRequired,
  logo: PropTypes.object
}
