import React, { useLayoutEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { findUrl } from '../../helpers/findUrl'
import DynamicZoneMainHeader from '../../helpers/createDynamicZoneMainHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { TranslateOverlay } from './TranslateOverlay'
import Icon from '../icon'

import { useTheme } from '../../context/themeContext'
import { Moon, Sun } from './../headerIcons'
import Carousel from '../carousel'

const IndexHeader = ({ page, header }) => {
  const data = useStaticQuery(graphql`
    query {
      allSitePage(
        filter: {
          componentChunkName: {
            in: [
              "component---src-templates-main-render-ou-js"
              "component---src-templates-internal-page-js"
            ]
          }
        }
      ) {
        nodes {
          path
        }
      }
    }
  `)

  const { allSitePage: matchPages } = data

  const [ languageChangeDesktop, setLanguageChangeDesktop ] = useState(false)
  const [ languageChangeMobile, setLanguageChangeMobile ] = useState(false)
  const { theme, toggleTheme } = useTheme()

  const [menuMobileActive, setMenuMobileActive] = useState(false)
  
  const handleClickMenuMobile = () => {
    setMenuMobileActive(!menuMobileActive)
  }

  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useLayoutEffect(() => {
    const span = document.getElementsByClassName('navbar-toggler-icon')[0]
    window.addEventListener('scroll', handleScroll, { passive: true })
    if (scrollPosition >= 577) {
      span.style.backgroundImage =
        // eslint-disable-next-line quotes
        "url(\"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E\")"
    } else {
      span.style.backgroundImage =
        // eslint-disable-next-line quotes
        "url(\"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E\")"
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  return (
    <>
      <header
        className={'fixed-top background-header ' +
          (scrollPosition > 577 ? 'nav-primary-container' : '')
        }
      >
        <Navbar
          expand="lg"
          className={`${scrollPosition > 577 ? 'w-100 shadow ' : ''} 
            mx-auto flex-lg-column pt-lg-3 pb-lg-2 navbar mr-auto navbar-index-home navbar-scroll`}
          bg={`${
            scrollPosition > 577
              ? ' nav-primary-container navbar-scroll-style2'
              : 'transparent navbar-scroll-style'
          }`}
          variant={`${scrollPosition > 577 ? theme : 'dark'}`}
        >
          <div className="w-100 w-1setLanguageChange00 d-flex flex-column flex-lg-row">

            {/* Imagen del Navbar */}
            <div className="flex-grow-1 d-flex flex-row align-items-center">
              <Navbar.Brand>
                <Link to={page.primaryLogo?.url ? page.primaryLogo.url : '/'}>
                  <img
                    src={
                      scrollPosition > 577
                        ? (theme === 'dark' && page.secondaryLogoDark
                            ? page.secondaryLogoDark?.image.url
                            : page.secondaryLogo?.image.url) ||
                          page.primaryLogo?.image?.url
                        : page.primaryLogo?.image?.url
                    }
                    alt={
                      page.primaryLogo?.name ||
                      page.secondaryLogo?.name ||
                      'UCC'
                    }
                    className="logoIndex mr-2 "
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="index-navbar-nav"
                className="ml-auto pr-0 border-0"
                onClick={handleClickMenuMobile}
              />
            </div>

            {/* Button theme and translate on laptop */}
            <div className="d-flex flex-column">
              <div className="flex-grow-1 d-none d-lg-flex align-items-center justify-content-start justify-content-md-end featured-navbar-index font-secondary navbar-nav ">
                <div className="d-flex">
                  <div
                    className={`translate-overlay btn-sm ${scrollPosition > 577 ? 'nav-primary-color' : 'text-white'}`}
                  >
                    <button onClick={toggleTheme} className="theme-toggle">
                      {theme === 'dark' ? <Moon /> : <Sun />}
                    </button>
                    <FontAwesomeIcon
                      onClick={() => setLanguageChangeDesktop((prev) => !prev)}
                      icon={faGlobe}
                    />
                    {languageChangeDesktop && <TranslateOverlay />}
                  </div>
                </div>
                <DynamicZoneMainHeader
                  pages={matchPages}
                  position={scrollPosition}
                />
              </div>
            </div>
          </div>

          <Navbar.Collapse id="index-navbar-nav" className="w-100">
            {/* Button theme and translate on mobile */}
            <div className="row">
              <div className="col">
                <div className="d-lg-none d-flex align-items-center justify-content-start justify-content-md-end featured-navbar-index font-secondary navbar-nav border-top border-bottom py-3">
                  <div className="d-lg-none d-flex  ">
                    <div
                      className={`translate-overlay ${
                        scrollPosition > 577
                          ? 'nav-primary-color'
                          : 'text-white'
                      } btn-sm  `}
                    >
                      <button onClick={toggleTheme} className="theme-toggle">
                        {theme === 'dark' ? <Moon /> : <Sun />}
                      </button>
                      <FontAwesomeIcon
                        onClick={() => setLanguageChangeMobile((prev) => !prev)}
                        icon={faGlobe}
                      />
                      {languageChangeMobile && <TranslateOverlay />}
                    </div>
                  </div>
                  <DynamicZoneMainHeader pages={matchPages} />
                </div>
              </div>
            </div>
            
            <Nav
              className={`ml-auto flex-column flex-lg-row font-secondary font-weight-medium ${
                scrollPosition > 577
                  ? 'nav-dropdown-white'
                  : 'nav-dropdown-dark'
              }`}
            >
              {page.navbar?.map((menuItem, index) => {
                if (
                  /********* Map para navItems simples *********/
                  menuItem.organizational_unit ||
                  menuItem.url ||
                  menuItem.page
                ) {
                  if (menuItem.page && menuItem.page.slug) {
                    return (
                      <Link
                        to={findUrl(menuItem.page.slug, matchPages.nodes)}
                        key={`menuPageId-${index}`}
                        className="nav-link nav-link-text text-uppercase d-flex flex-row"
                      >
                        <Icon
                          {...menuItem.icon}
                          hasMargin={menuItem.name.trim()}
                        />
                        {menuItem.name}
                      </Link>
                    )
                  } else if (
                    menuItem.organizational_unit &&
                    menuItem.organizational_unit.slug
                  ) {
                    return (
                      <Link
                        to={`/${menuItem.organizational_unit?.slug}`}
                        key={`menuLinkId-${index}`}
                        className="nav-link nav-link-text text-uppercase d-flex flex-row"
                      >
                        <Icon
                          {...menuItem.icon}
                          hasMargin={menuItem.name.trim()}
                        />
                        {menuItem.name}
                      </Link>
                    )
                  } else if (menuItem.url) {
                    return (
                      <a
                        href={menuItem.url || ''}
                        target="_blank"
                        className="nav-link nav-link-text text-uppercase d-flex flex-row"
                        key={`menuOrganizationalUnitId-${index}`}
                        rel="noreferrer"
                      >
                        <Icon
                          {...menuItem.icon}
                          hasMargin={menuItem.name.trim()}
                        />
                        {menuItem.name}
                      </a>
                    )
                  } else return null
                } else if (
                  /********* Map para navItems dropdowns *********/
                  menuItem.organizationalUnit?.find((item) => item.organizational_unit.slug !== null) ||
                  menuItem.pages?.find((item) => item.page.slug !== null) ||
                  menuItem.ExternalLink ||
                  menuItem.multipleSite
                ) {
                  return (
                    <NavDropdown
                      title={`${menuItem.name}`}
                      id="index-navbar-nav"
                      key={`navBarId-${index}`}
                      className="nav-link-text text-uppercase space-nav-resposive"
                    >
                      {/* Map pages */}
                      <div
                        className={`nav-scroll ${
                          scrollPosition > 577
                            ? 'navbar-scroll-style2'
                            : 'navbar-scroll-style'
                        }`}
                      >
                        {menuItem.pages?.map((dropdown, index) => {
                          if (dropdown.page?.slug)
                            return (
                              <Link
                                to={findUrl(
                                  dropdown.page.slug,
                                  matchPages.nodes
                                )}
                                className="dropdown-item"
                                key={`menuPagesId-${index}`}
                              >
                                <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                  <Icon
                                    {...dropdown.icon}
                                    hasMargin={dropdown.name.trim()}
                                  />
                                  <small className="nav-text">
                                    {dropdown.name}
                                  </small>
                                </h6>
                              </Link>
                            )
                        })}
                      </div>

                      {/* Map organizational units */}
                      <div
                        className={`nav-scroll ${
                          scrollPosition > 577
                            ? 'navbar-scroll-style2'
                            : 'navbar-scroll-style'
                        }`}
                      >
                        {menuItem.organizationalUnit?.map(
                          (dropdown, index) => {
                            if (dropdown.organizational_unit?.slug)
                              return (
                                <Link
                                  to={`/${dropdown.organizational_unit?.slug}`}
                                  className="dropdown-item"
                                  key={`menuOrganizationalUnitId-${index}`}
                                >
                                  <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                    <Icon
                                      {...dropdown.icon}
                                      hasMargin={dropdown.organizational_unit.name.trim()}
                                    />
                                    <small className="nav-text">
                                      {dropdown?.name ||
                                        dropdown.organizational_unit.name}
                                    </small>
                                  </h6>
                                </Link>
                              )
                          }
                        )}
                      </div>

                      {/* Map external links */}
                      <div
                        className={`nav-scroll ${
                          scrollPosition > 577
                            ? 'navbar-scroll-style2'
                            : 'navbar-scroll-style'
                        }`}
                      >
                        {menuItem.ExternalLink?.map((dropdown, index) => {
                          if (dropdown?.url)
                            return (
                              <a
                                href={dropdown.url || ''}
                                target="_blank"
                                className="dropdown-item"
                                key={`menuOrganizationalUnitId-${index}`}
                                rel="noreferrer"
                              >
                                <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                  <Icon
                                    {...dropdown.icon}
                                    className="mr-1"
                                    hasMargin={dropdown.name.trim()}
                                  />
                                  <small className="nav-text">
                                    {dropdown.name}
                                  </small>
                                </h6>
                              </a>
                            )
                        })}
                      </div>

                      {/* Map MultipleSites */}
                      <div
                        className={`nav-scroll ${
                          scrollPosition > 577
                            ? 'navbar-scroll-style2'
                            : 'navbar-scroll-style'
                        }`}
                      >
                        {menuItem.multipleSite?.map((dropdown, index) => {
                          switch (true) {
                            case dropdown.page !== null:
                              return (
                                <Link
                                  to={findUrl(
                                    dropdown.page.slug,
                                    matchPages.nodes
                                  )}
                                  className="dropdown-item"
                                  key={`menuPagesId-${index}`}
                                >
                                  <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                    <Icon
                                      {...dropdown.icon}
                                      hasMargin={dropdown.name.trim()}
                                    />
                                    <small className="nav-text">
                                      {dropdown.name}
                                    </small>
                                  </h6>
                                </Link>
                              )

                            case dropdown.organizational_unit !== null:
                              return (
                                <Link
                                  to={`/${dropdown.organizational_unit?.slug}`}
                                  className="dropdown-item"
                                  key={`menuOrganizationalUnitId-${index}`}
                                >
                                  <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                    <Icon
                                      {...dropdown.icon}
                                      hasMargin={dropdown.organizational_unit.name.trim()}
                                    />
                                    <small className="nav-text">
                                      {dropdown?.name ||
                                        dropdown.organizational_unit.name}
                                    </small>
                                  </h6>
                                </Link>
                              )

                            case dropdown.url !== null:
                              return (
                                <a
                                  href={dropdown.url}
                                  target="_blank"
                                  className="dropdown-item"
                                  key={`menuUrlId-${index}`}
                                  rel="noreferrer"
                                >
                                  <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                    <Icon
                                      {...dropdown.icon}
                                      className="mr-1"
                                      hasMargin={dropdown.name.trim()}
                                    />
                                    <small className="nav-text">
                                      {dropdown.name}
                                    </small>
                                  </h6>
                                </a>
                              )

                            case dropdown.novelty !== null:
                              return (
                                <Link
                                  to={`/noticias/${dropdown.novelty?.slug}`}
                                  className="dropdown-item"
                                  key={`menuNoveltyId-${index}`}
                                >
                                  <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                    <Icon
                                      {...dropdown.icon}
                                      hasMargin={dropdown.novelty.title.trim()}
                                    />
                                    <small className="nav-text">
                                      {dropdown?.name ||
                                        dropdown.novelty.title}
                                    </small>
                                  </h6>
                                </Link>
                              )

                            case dropdown.note !== null:
                              return (
                                <Link
                                  to={`/notas/${dropdown.note?.slug}`}
                                  className="dropdown-item"
                                  key={`menuNoteId-${index}`}
                                >
                                  <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                    <Icon
                                      {...dropdown.icon}
                                      hasMargin={dropdown.note.title.trim()}
                                    />
                                    <small className="nav-text">
                                      {dropdown?.name ||
                                        dropdown.note.title}
                                    </small>
                                  </h6>
                                </Link>
                              )
                          
                            case dropdown.ucc_career !== null:
                              return (
                                <Link
                                  to={`/carreras/${dropdown.ucc_career?.slug}`}
                                  className="dropdown-item"
                                  key={`menuCareerId-${index}`}
                                >
                                  <h6 className="text-wrap-lg text-uppercase mb-0 py-2 d-flex flex-row">
                                    <Icon
                                      {...dropdown.icon}
                                      hasMargin={dropdown.ucc_career.name.trim()}
                                    />
                                    <small className="nav-text">
                                      {dropdown?.name ||
                                        dropdown.ucc_career.name}
                                    </small>
                                  </h6>
                                </Link>
                              )

                            default:
                              break;
                          }
                        })}
                      </div>

                    </NavDropdown>
                  )
                } else return null
              })}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>

      {header && header?.slide.length > 0 && (
        <div className="d-flex flex-column header-carousel-index">
          <Carousel
            carousel={header}
            isHome={true}
            menuMobileActive={menuMobileActive}
          />
        </div>
      )}
    </>
  )
}

IndexHeader.propTypes = {
  page: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired
}

export default IndexHeader
