import React from 'react'
import PropTypes from 'prop-types'
import { createCssCustomButton } from '../helpers/createCssCustomButton'
import { createNavigationUrl } from '../helpers/createNavigationUrl'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Icon from './icon'

export const Button = ({
  icon,
  button,
  customButton,
  classContainer,
  classButton
}) => {
  const pages = useStaticQuery(graphql`
    query {
      allSitePage(
        filter: {
          componentChunkName: {
            in: [
              "component---src-templates-main-render-ou-js"
              "component---src-templates-internal-page-js"
            ]
          }
        }
      ) {
        nodes {
          path
        }
      }
    }
  `)

  const ButtonIcon = () => icon ? <Icon style={{ marginRight: '.5em' }} {...icon} /> : null

  return (
    <>
      {button.url ? (
        <div className={classContainer || 'my-5 d-flex justify-content-center'}>
          <a
            className={
              createCssCustomButton(customButton) +
                ' d-flex flex-row justify-content-center align-items-center ' +
                classButton || ''
            }
            href={
              button.url.includes('https://')
                ? button.url
                : `https://${button.url}`
            }
            target="about_blank"
          >
            <ButtonIcon />
            {button.label}
          </a>
        </div>
      ) : (
        <div className={classContainer || 'my-5 d-flex justify-content-center'}>
          <Link
            className={
              createCssCustomButton(customButton) +
                ' d-flex flex-row justify-content-center align-items-center ' +
                classButton || ''
            }
            to={createNavigationUrl(pages.allSitePage.nodes, button)}
          >
            <ButtonIcon />
            {button.label}
          </Link>
        </div>
      )}
    </>
  )
}

Button.propTypes = {
  icon: PropTypes.object,
  button: PropTypes.object.isRequired,
  customButton: PropTypes.object.isRequired,
  classContainer: PropTypes.string,
  classButton: PropTypes.string
}
