/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import IndexHeader from './home/indexHeader'
import Header from './header'
import Footer from './footer'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/theme.scss'
import { Helmet } from 'react-helmet'
import useScripts, { scriptSpecialCases } from '../hooks/useScripts'
import ThemeProvider from '../context/themeContext'

const Layout = ({ children, page, logo, indexHeader = false, header, scriptSelect = [] }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const scripts = useScripts()

  const allScripts = [...scripts, ...scriptSelect];

  const renderedScripts = allScripts.map((script) =>
    script.enable === true ? (
      script.name.toLowerCase() === 'google-analytics' ||
      script.name.toLowerCase() === 'facebook-pixel' ||
      script.name.toLowerCase() === 'matomo-tag-manager' ||
      script.name.toLowerCase() === 'google-tag-manager' ? (
        scriptSpecialCases(script)
      ) : (
        <script
          src={script.source}
          type="text/javascript"
          name={script.name}
          siteId={script.siteId}
          key={script.id}
          async
        />
      )
    ) : null
  );

  return (
    <ThemeProvider>
      <div className="min-vh-100 d-flex flex-column">
        {indexHeader ? (
          <IndexHeader
            siteTitle={data.site.siteMetadata?.title || 'Title'}
            page={page}
            header={header}
          />
        ) : (
          <Header
            siteTitle={data.site.siteMetadata?.title || 'Title'}
            page={page}
            logo={logo}
          />
        )}

        {/* Helmet para scripts en todas las paginas */}
        {renderedScripts}
        
        {/* ThemaMinimal de SweetAlert */}
        <Helmet>
          <link
            href="//cdn.jsdelivr.net/npm/@sweetalert2/theme-minimal@4/minimal.css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="@sweetalert2/themes/minimal/minimal.css"
          />
          <script
            src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
            integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
            crossOrigin="anonymous"
          ></script>
          <script
            src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js"
            integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns"
            crossOrigin="anonymous"
          ></script>
        </Helmet>

        <main className="flex-grow-1">{children}</main>
        
        <Footer />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.object.isRequired,
  logo: PropTypes.object,
  indexHeader: PropTypes.bool,
  header: PropTypes.object,
  scriptSelect: PropTypes.array
}

export default Layout
