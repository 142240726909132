import React, { useState, useEffect } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Carousel } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { useWindowSize } from '../hooks/useWindowSize'
import { createNavigationUrl } from '../helpers/createNavigationUrl'

const Carousels = ({
  carousel,
  keyName = '',
  isHome = false,
  menuMobileActive = false
}) => {
  const pages = useStaticQuery(graphql`
    query {
      allSitePage(
        filter: {
          componentChunkName: {
            in: [
              "component---src-templates-main-render-ou-js"
              "component---src-templates-internal-page-js"
            ]
          }
        }
      ) {
        nodes {
          path
        }
      }
    }
  `)

  const [slideStates, setSlidesState] = useState(null)
  const [width] = useWindowSize()
  useEffect(() => {
    setSlidesState(
      carousel.slide
        ? (carousel.slide?.filter((slide) => slide.visible === true)).length
        : null
    )
  }, [carousel])

  const { allSitePage: matchPages } = pages
  return (
    <>
      {carousel?.visible && (
        <Carousel
          className="carousel custom-carousel flex-grow-1 d-flex"
          slide={slideStates > 1}
          indicators={slideStates > 1}
          controls={slideStates > 1}
        >
          {carousel.slide?.length > 0
            ? carousel.slide.map((slide, i) => {
                if (slide.visible) {
                  return (
                    <Carousel.Item
                      interval={10000}
                      key={`idSlide-${keyName + i}`}
                      className="h-100"
                    >
                      <div
                        role="img"
                        alt={slide.imageDescription || ''}
                        className="h-100 d-flex video-mobile"
                        style={{
                         backgroundImage: slide.backgroundImage?.url
                            ? `url(${slide.backgroundImage.url})`
                            : '',
                          backgroundPosition: 'center'
                        }}
                      >
                        {slide?.backgroundVideo && width >= 1200 && (
                          <div className="position-absolute video-background w-100 h-100 d-flex align-items-center justify-content-between">
                            <div className="carousel-embed fade-in-short">
                              <video
                                src={slide.backgroundVideo.url}
                                preload="auto"
                                className="carousel-video"
                                autoPlay
                                muted
                                loop
                              />
                            </div>
                          </div>
                        )}

                        {/* Aplicando FIltros a Imagenes de los "slides" */}
                        <div
                          className={`${
                            isHome
                              ? 'slide-background-home'
                              : slide.imageFilter && slide.imageFilter === true
                              ? 'slide-background'
                              : null
                          } flex-grow-1 d-flex flex-column justify-content-center text-white`}
                        >
                          {slide.content && (
                            <div className="container">
                              <div className="row">
                                <div className="col-12 col-mobile-fix">
                                  <div className="p-5 info-cont markdown-carousel-mobile">
                                    {!menuMobileActive ? (
                                      <div
                                        className={
                                          slide.content != null ||
                                          slide.novelty?.slug ||
                                          slide.diary?.slug ||
                                          slide.organizational_unit?.slug ||
                                          slide.page?.slug ||
                                          slide.ucc_career?.slug ||
                                          slide.url
                                            ? 'slide-card'
                                            : null
                                        }
                                      >
                                        <ReactMarkdown
                                          escapeHtml={false}
                                          source={slide.content}
                                          className="format-markdown-container fade-in-image markdown-format markdown-format-white mb-2"
                                        />
                                        {!isHome ? (
                                          slide.novelty?.slug ||
                                          slide.diary?.slug ||
                                          slide.organizational_unit?.slug ||
                                          slide.page?.slug ||
                                          slide.ucc_career?.slug ? (
                                            <Link
                                              to={createNavigationUrl(
                                                matchPages.nodes,
                                                slide
                                              )}
                                              className="btn btn-sm btn-outline-light"
                                              key={`slide-slug-${i}`}
                                            >
                                              {slide.textButton
                                                ? slide.textButton
                                                : 'VER MÁS'}
                                            </Link>
                                          ) : slide.url ? (
                                            <a
                                              href={slide.url}
                                              className="btn btn-sm btn-outline-light"
                                              target="about_blank"
                                              key={`slide-link-${i}`}
                                            >
                                              {slide.textButton
                                                ? slide.textButton
                                                : 'IR A SITIO'}
                                            </a>
                                          ) : null
                                        ) : slide.novelty?.slug ||
                                          slide.diary?.slug ||
                                          slide.organizational_unit?.slug ||
                                          slide.page?.slug ||
                                          slide.ucc_career?.slug ? (
                                          <Link
                                            to={createNavigationUrl(
                                              matchPages.nodes,
                                              slide
                                            )}
                                            className="btn btn-md btn-outline-light rounded-pill pl-3 pr-3 mt-2 font-weight-medium"
                                            key={`slide-slug-${i}`}
                                          >
                                            {slide.textButton
                                              ? slide.textButton
                                              : 'VER MÁS'}
                                          </Link>
                                        ) : slide.url ? (
                                          <a
                                            href={slide.url}
                                            className="btn btn-md  btn-outline-light rounded-pill pl-3 pr-3 mt-2 font-weight-medium"
                                            target="about_blank"
                                            key={`slide-link-${i}`}
                                          >
                                            {slide.textButton
                                              ? slide.textButton
                                              : 'IR A SITIO'}
                                          </a>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Carousel.Item>
                  )
                } else {
                  return null
                }
              })
            : null}
        </Carousel>
      )}
    </>
  )
}

export default Carousels

Carousels.propTypes = {
  carousel: PropTypes.object.isRequired,
  keyName: PropTypes.string,
  isHome: PropTypes.bool,
  menuMobileActive: PropTypes.bool,
  pages: PropTypes.array
}
