import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { ExternalLink } from '../components/main-header/externalLink'
import { InternalLink } from '../components/main-header/internalLink'
import { MultipleExternalLink } from '../components/main-header/multipleExternalLink'
import {
  EXTERNAL_LINK,
  MULTIPLE_EXTERNAL_LINK,
  INTERNAL_LINK,
  ORGANIZATIONAL_LINK
} from '../utils/variables/dynamicZoneMainHeader'

const DynamicZoneMainHeader = ({ pages, position }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiMainHeader {
        main {
          icon {
            code
            type
          }
          page {
            slug
          }
          organizational_unit {
            slug
          }
          type
          url
          strapi_component
          name
          dropdownlink {
            name
            url
            id
          }
        }
      }
    }
  `)
  return data.strapiMainHeader?.main.map((component, index) => {
    switch (component.strapi_component) {
      case EXTERNAL_LINK:
        return (
          <ExternalLink
            icon={component.icon}
            key={'dynamic-header-' + index}
            data={component}
            scroll={position}
          />
        )
      case MULTIPLE_EXTERNAL_LINK:
        return (
          <MultipleExternalLink
            icon={component.icon}
            key={'dynamic-header-' + index}
            data={component}
            scroll={position}
          />
        )
      case ORGANIZATIONAL_LINK:
      case INTERNAL_LINK:
        return (
          <InternalLink
            icon={component.icon}
            key={'dynamic-header-' + index}
            data={component}
            pages={pages}
            scroll={position}
          />
        )
    }
  })
}

export default DynamicZoneMainHeader
