import React, { useLayoutEffect } from 'react'

export const TranslateOverlay = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      { pageLanguage: 'es' },
      'google_translate_element'
    )
  }

  useLayoutEffect(() => {
    const addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
    return () => {
      document.body.removeChild(addScript)
    }
  }, [])
  return <div id="google_translate_element"></div>
}
