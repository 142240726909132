export const findUrl = (slug, pages) => {
  if (slug && pages) {
    const splitPages = pages.map(({ path }) => path.split('/')) //Dividimos las pages para poder comparar con Slug.

    const filtro = splitPages.filter((e) => e[e.length - 1] === slug) //Filtramos a donde debe ir redireccionada la pagina comparando splitPages con Slug.

    const deleteSpace = filtro.map((a) => {
      //Sacamos un espacio en blanco que quedo de anterior funcion 'filtro'.
      return a.slice(1)
    })

    const createUrl = deleteSpace.map((e) => {
      //Concatenamos todos los elemenos del array con '/' para obtener la redireccion que corresponde.
      return e.join('/')
    })

    return createUrl ? '/' + createUrl : null
  } else {
    return ''
  }
}
