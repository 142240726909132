import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './font-awesome'
import { findUrl } from '../helpers/findUrl'
import { Button } from './button'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiGlobalConfig {
        nodes {
          fiscalData {
            url
            visible
            title
            image {
              url
            }
          }
        }
      }
      strapiFooter {
        buttonList {
          icon {
            code
            type
          }
          button {
            url
            label
            organizational_unit {
              name
              slug
            }
            page {
              slug
              name
            }
          }
          customButton {
            shape
            size
            variant
          }
        }
        logosList {
          logos {
            title
            visible
            image {
              url
            }
            url
          }
        }
        listsSection {
          linkList {
            label
            url
            page {
              slug
            }
          }
          label
        }
      }
      allSitePage(
        filter: {
          componentChunkName: {
            in: [
              "component---src-templates-main-render-ou-js"
              "component---src-templates-internal-page-js"
            ]
          }
        }
      ) {
        nodes {
          path
        }
      }
    }
  `)

  const {
    strapiFooter: footer,
    allSitePage: pages,
    allStrapiGlobalConfig: globalConfig
  } = data
  const fiscalData = globalConfig.nodes[0].fiscalData || {}
  
  return (
    <footer className="py-5 background-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 col-lg-3 d-flex mb-4 justify-content-center">
            <div className="d-flex flex-column align-items-center ">
              <div className="d-flex mb-2 pt-2">
                <a
                  href="https://es-la.facebook.com/UniversidadCatolicaCordoba/"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-facebook"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} size="sm" />
                </a>
                <a
                  href="https://www.youtube.com/uccoficial"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-youtube"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'youtube']} size="sm" />
                </a>
                <a
                  href="https://ar.linkedin.com/school/universidad-cat-lica-de-c-rdoba/"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-linkedin"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="sm" />
                </a>
                <a
                  href="https://www.instagram.com/uccoficial/?hl=es-la"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-instagram"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'instagram']} size="sm" />
                </a>
                <a
                  href="https://twitter.com/UCCoficial"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-twitter"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'x-twitter']} size="sm" />
                </a>
                <a
                  href="https://www.tiktok.com/@uccoficial"
                  target="_blank"
                  className="btn btn-social m-1 btn-social-icon btn-tiktok"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'tiktok']} size="sm" />
                </a>
              </div>
              <h5 className="text-white font-weight-medium text-center">
                ucc.edu.ar
              </h5>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-9 mb-4">
            <div className="d-flex flex-wrap align-items-center responsive-logos-footer">
              {footer?.logosList?.logos.length > 0
                ? footer.logosList.logos.map((logo, i) =>
                    logo.visible ? (
                      <a
                        href={logo.url}
                        target="about_blank"
                        key={`key-logo-footer-${i}`}
                      >
                        <img
                          src={logo.image?.url}
                          className="img-small-logo-footer my-2 mx-1"
                          alt="logoFooter01"
                        />
                      </a>
                    ) : null
                  )
                : null}
            </div>
          </div>
          <div className="col-12 col-md row mx-0">
            {footer.listsSection?.length > 0
              ? footer.listsSection.map((list, i) => {
                  return (
                    <div
                      className="col-12 col-md-3 my-3"
                      key={`list-section-key-${i}`}
                    >
                      <div className="d-flex flex-column text-white">
                        <h6 className="text-uppercase">{list.label}</h6>
                        {list.linkList?.length > 0
                          ? list.linkList.map((item, i) =>
                              item.page !== null ? (
                                <Link
                                  to={findUrl(item.page.slug, pages.nodes)}
                                  className="text-white mb-2"
                                  key={`item-list-link-${i}`}
                                >
                                  {item.label}
                                </Link>
                              ) : (
                                <a
                                  href={item.url}
                                  className="text-white mb-2"
                                  target="about_blank"
                                  key={`item-list-a-${i}`}
                                >
                                  {item.label}
                                </a>
                              )
                            )
                          : null}
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
          <div className="col-12 col-md-4 col-lg-2 my-3">
            <div className=" d-flex flex-column font-secondary labels-font-size responsive-buttons">
              {footer?.buttonList.length > 0 &&
                footer.buttonList.map((button, index) => (
                  <Button
                    classButton={`w-100 text-left font-weight-500 rounded-0 ${
                      button?.customButton?.variant === 'primary'
                        ? ' border-none'
                        : ''
                    }`}
                    key={'footer-button-' + index}
                    {...button}
                    icon={button?.icon}
                    classContainer="mb-2 d-flex flex-grow-1"
                  />
                ))}
              {fiscalData?.visible && (
                <a
                  className="btn-dark border-ucc-boton btn-outline-info d-flex justify-content-center align-items-center mb-2 flex-grow-1"
                  href={fiscalData.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="text-black" src={fiscalData.image.url} alt={fiscalData.title} />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
