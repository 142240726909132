import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import { createNavigationUrl } from '../../helpers/createNavigationUrl'
import Icon from '../icon'

export const InternalLink = ({ data, pages, icon, scroll }) => {
  switch (data.type) {
    case 'link':
      return (
        <>
          <Link
            to={createNavigationUrl(pages?.nodes, data)}
            className={
              'nav-link text-uppercase font-weight-bolder d-flex flex-row'
            }
          >
            {icon && <Icon {...icon} hasMargin={true}/>}
            {data.name}
          </Link>
        </>
      )
    case 'button':
      return (
        <Link
          to={createNavigationUrl(pages?.nodes, data)}
          className="btn btn-md rounded-pill d-flex px-3 ucc-background-color border-ucc-boton text-white font-weight-normal flex-row"
          target="_blank"
          rel="noreferrer"
        >
          {icon && <Icon {...icon} hasMargin={true}/>}
          {data.name}
        </Link>
      )
    case 'buttonWhite':
      return (
        <div
          className={`btn btn-md rounded-pill d-flex px-3 border-ucc-boton font-weight-bolder flex-row text-uppercase nav-button-white ${
            scroll < 577 ? 'border-ucc-boton' : 'nav-scroll-bottom'
          }`}
        >
          <Link
            to={createNavigationUrl(pages?.nodes, data)}
            target="_blank"
            rel="noreferrer"
          >
            {icon && <Icon {...icon} hasMargin={true}/>}
            {data.name}
          </Link>
        </div>
      )

    default:
      return (
        <>
          <Link
            to={createNavigationUrl(pages?.nodes, data)}
            className={
              'nav-link text-uppercase font-weight-bolder d-flex flex-row'
            }
          >
            {icon && <Icon {...icon} hasMargin={true}/>}
            {data.name}
          </Link>
        </>
      )
  }
}

InternalLink.propTypes = {
  icon: PropTypes.object,
  data: PropTypes.object.isRequired,
  pages: PropTypes.object
}
