import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

// icon libraries:
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas)
library.add(far)
library.add(fab)

const Icon = ({ type, code, hasMargin, iconSize, ...extraParams }) => {
  if (!type || !code) return null

  return (
    <div
      {...extraParams}
      className={hasMargin ? 'mr-1 ' : '' + extraParams.className || ''}
    >
      <FontAwesomeIcon icon={[type, code.replace(/^fa-/, '')]} className={iconSize}/>
    </div>
  )
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  hasMargin: PropTypes.bool
}

export default Icon
