export const createSizeCustomButton = (size) => {
  let makeSize = 'btn shadow-sm'
  if (size === 'small') makeSize += ' btn-sm'
  if (size === 'normal') makeSize += ''
  if (size === 'big') makeSize += ' btn-lg'

  return makeSize
}

export const createShapeCustomButton = (shape) => {
  let makeShape = ''
  if (shape === 'rectangle') makeShape += ''

  if (shape === 'pill') makeShape += ' rounded-pill'

  return makeShape
}

export const createVariantCustomButton = (variant) => {
  let makeVariant = ''

  if (variant === 'primary') makeVariant += ' btn-info border-ucc-boton'
  if (variant === 'secondary') makeVariant += ' btn-blue'
  if (variant === 'light') makeVariant += ' btn-white'
  if (variant === 'dark') makeVariant += ' btn-dark'

  if (variant === 'primary_outline') makeVariant += ' btn-outline-info'
  if (variant === 'secondary_outline') makeVariant += ' btn-outline-blue'
  if (variant === 'light_outline') makeVariant += ' btn-outline-light'
  if (variant === 'dark_outline') makeVariant += ' btn-outline-dark'

  return makeVariant
}

export const createCssCustomButton = (customButton) => {
  let makeClass =
    createSizeCustomButton(customButton.size) +
    createVariantCustomButton(customButton.variant) +
    createShapeCustomButton(customButton.shape)

  return makeClass
}
