module.exports = {
  DIARIES_ROUTE: 'agenda',
  NEWS_ROUTE: 'noticias',
  NOTES_ROUTE: 'notas',
  CAREERS_ROUTE: 'carreras',
  CAREERS_FEEDS_ROUTE: 'propuesta-academica',
  HOME_PATH: 'src/pages/home.js',
  MAIN_RENDER_PATH: 'src/templates/main-render-ou.js',
  FEED_DIARIES_ALL_PATH: 'src/templates/diariesFeeds/diaries-all.js',
  FEED_DIARIES_BY_CATEGORY_PATH:
    'src/templates/diariesFeeds/diaries-byCategory.js',
  FEED_DIARIES_OU_ALL_PATH: 'src/templates/diariesFeeds/diaries-ou-all.js',
  FEED_DIARIES_OU_BY_CATEGORY_PATH:
    'src/templates/diariesFeeds/diaries-ou-byCategory.js',
  FEED_NEWS_OU_ALL_PATH: 'src/templates/newsFeeds/news-ou-all.js',
  FEED_NEWS_OU_BY_CATEGORY_PATH:
    'src/templates/newsFeeds/news-ou-byCategories.js',
  FEED_NEWS_ALL_PATH: 'src/templates/newsFeeds/news-all.js',
  FEED_NEWS_BY_CATEGORY_PATH: 'src/templates/newsFeeds/news-byCategory.js',
  FEED_NOTES_ALL_PATH: 'src/templates/notesFeeds/notes-all.js',
  CAREER_FEEDS_PATH: 'src/templates/careerFeeds/career.js',
  HEALTH_CAREER_FEEDS_PATH: 'src/templates/careerFeeds/careerHealthSpeciality.js',
  INTERNAL_PAGE_PATH: 'src/templates/internal-page.js',
  NOTE_PAGE_PATH: 'src/templates/note-page.js',
  CAREER_PAGE_PATH: 'src/templates/career-page.js',
  NEWS_PAGE_PATH: 'src/templates/novelties-page.js',
  DIARY_PAGE_PATH: 'src/templates/diary-page.js',
  GRADO: 'grado',
  POSGRADO: 'posgrado',
  PREGRADO: 'pregrado',
  LANDING_ROUTE: 'landing',
  LANDING_PATH: 'src/templates/landingPages/landing-page.js',
}
