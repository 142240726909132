import React from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'

export const MultipleExternalLink = ({ data, scroll }) => {
  switch (data.type) {
    case 'link':
      return (
        <Nav className="font-secondary font-weight-medium nav-dropdown-white">
          <NavDropdown
            id="index-main"
            title={data.name}
            className=" text-uppercase text-white d-flex flex-column justify-content-center align-items-center"
          >
            {data.dropdownlink?.length > 0 &&
              data.dropdownlink.map((item) => {
                return (
                  <a
                    href={item.url}
                    target="_blank"
                    className="btn-multiple-text dropdown-item"
                    rel="noreferrer"
                    key={`${item.name}-${item.id}`}
                  >
                    <h6 className="text-wrap-lg text-uppercase mb-0 py-2 font-weight-medium">
                      <small>{item.name}</small>
                    </h6>
                  </a>
                )
              })}
          </NavDropdown>
        </Nav>
      )
    case 'button':
      return (
        <div className={`btn-group  d-flex flex-column justify-content-center align-items-center ${
          scroll > 577
            ? 'nav-dropdown-white'
            : 'nav-dropdown-dark'
        }`}>
          <button
            type="button"
            className=" btn btn-md  rounded-pill px-3  ucc-background-color border-ucc-boton text-white dropdown-toggle spaceTextIcon "
            data-toggle="dropdown"
          >
            {data.name}
          </button>
          <div className="dropdown-menu dropdown-menu-right ">
            {data.dropdownlink?.length > 0 &&
              data.dropdownlink.map((item) => (
                <a
                  className="btn-multiple-text dropdown-item font-weight-normal"
                  type="button"
                  target="_blank"
                  rel="noreferrer"
                  href={item.url}
                  key={`${item.name}-${item.id}`}
                >
                  {item.name}
                </a>
              ))}
          </div>
        </div>
      )
    case 'buttonWhite':
      return (
        <div className="btn-group  d-flex flex-column justify-content-center align-items-center">
          <button
            type="button"
            className={`btn btn-md rounded-pill px-3 nav-button-white dropdown-toggle spaceTextIcon ${
              scroll < 577 ? 'border-ucc-boton' : 'nav-scroll-bottom'
            }`}
            data-toggle="dropdown"
          >
            {data.name}
          </button>
          <div className="dropdown-menu dropdown-menu-right ">
            {data.dropdownlink?.length > 0 &&
              data.dropdownlink.map((item) => (
                <a
                  className="btn-multiple-text dropdown-item font-weight-normal"
                  type="button"
                  target="_blank"
                  rel="noreferrer"
                  href={item.url}
                  key={`${item.name}-${item.id}`}
                >
                  {item.name}
                </a>
              ))}
          </div>
        </div>
      )

    default:
      return (
        <Nav className="font-secondary font-weight-medium nav-dropdown-white">
          <NavDropdown
            id="index-main"
            title={data.name}
            className=" text-uppercase text-white d-flex flex-column justify-content-center align-items-center"
          >
            {data.dropdownlink?.length > 0 &&
              data.dropdownlink.map((item) => {
                return (
                  <a
                    href={item.url}
                    target="_blank"
                    className="dropdown-item"
                    rel="noreferrer"
                    key={`${item.name}-${item.id}`}
                  >
                    <h6 className="text-wrap-lg text-uppercase mb-0 py-2 font-weight-medium">
                      <small>{item.name}</small>
                    </h6>
                  </a>
                )
              })}
          </NavDropdown>
        </Nav>
      )
  }
}

MultipleExternalLink.propTypes = {
  data: PropTypes.object.isRequired
}
