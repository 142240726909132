import { findUrl } from './findUrl'
import urlSlug from 'url-slug'
import { GRADO } from '../utils/variables/gatsbyNodeVariables'

export const createNavigationUrl = (pages, relations) => {
  if (relations.page) return findUrl(relations.page.slug, pages)

  if (relations.ucc_career) return `/carreras/${relations.ucc_career.slug}`

  if (relations.organizational_unit)
    return `/${relations.organizational_unit.slug}`

  if (relations.novelty) return `/noticias/${relations.novelty.slug}`

  if (relations.diary) return `/agenda/${relations.diary.slug}`

  if (relations.url) return relations.url

  if (relations.career_level)
    return `/propuesta-academica/${
      relations.career_level.name.toLowerCase() === GRADO
        ? 'carreras'
        : urlSlug(relations.career_level.name)
    }`

  return '/'
}
