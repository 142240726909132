import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icon'

export const ExternalLink = ({ data, icon, scroll }) => {
  switch (data.type) {
    case 'link':
      return (
        <a
          href={data.url}
          className={
            'nav-link btn-sm text-uppercase font-weight-bolder d-flex flex-row'
          }
          target="_blank"
          rel="noreferrer"
        >
          {icon && <Icon {...icon} hasMargin={true}/>}
          {data.name}
        </a>
      )
    case 'button':
      return (
        <a
          href={data.url}
          className="btn btn-md rounded-pill d-flex px-3  ucc-background-color border-ucc-boton text-white font-weight-normal d-flex flex-row"
          target="_blank"
          rel="noreferrer"
        >
          {icon && <Icon {...icon} hasMargin={true}/>}
          {data.name}
        </a>
      )
    case 'buttonWhite':
      return (
        <div
          className={`btn btn-md rounded-pill d-flex px-3 border-ucc-boton font-weight-bolder flex-row text-uppercase nav-button-white  ${
            scroll < 577 ? 'border-ucc-boton' : 'nav-scroll-bottom'
          }`}
        >
          <a 
            href={data.url} 
            target="_blank" 
            rel="noreferrer"
          >
            {icon && <Icon {...icon} hasMargin={true}/>}
            {data.name}
          </a>
        </div>
      )

    default:
      return (
        <a
          href={data.url}
          className={
            'nav-link btn-sm text-uppercase font-weight-bolder d-flex flex-row'
          }
          target="_blank"
          rel="noreferrer"
        >
          <Icon {...icon} hasMargin={data.name?.trim()} />
          {data.name}
        </a>
      )
  }
}

ExternalLink.propTypes = {
  icon: PropTypes.object,
  data: PropTypes.object.isRequired
}
